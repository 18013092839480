/* eslint-disable eqeqeq */
import axios from 'axios'
import router from '../router/index'
import { Message } from 'element-ui'
// axios.defaults.baseUR = process.env.VUE_APP_BASE_URL
if (process.env.NODE_ENV === 'development') {
  // 开发环境
  axios.defaults.baseURL = '/api'
} else {
  // 生产环境
  axios.defaults.baseURL = 'https://www.路航通.com/api'
  // axios.defaults.baseURL = 'http://119.3.168.127/api'
}
// axios.defaults.timeout = 30000
axios.interceptors.request.use((config) => {
  config.headers.Authorization = window.localStorage.getItem('token')
  return config
}, (error) => {
  console.log(error)
  if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
    Message.error('服务超时,请刷新页面')
  }
  return Promise.reject(error)
})

axios.interceptors.response.use((res) => {
  if (res.data.code && res.data.code !== '000') {
    // Message.error(res.msg)
    return res
  } else {
    // 正常处理
    return res
  }
}, (error) => {
  const errStr = error.toString()
  const errMa = errStr.substr(errStr.length - 3)
  if (errMa === '401') {
    if (error.response.data.msg) {
      Message.error(error.response.data.msg)
    } else {
      Message.error('权限有误')
      router.push({ path: '/' })
    }
  } else {
    Message.error(error.response.data.msg)
  }
  return Promise.reject(error)
})
// post 请求
export function fetchPost (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params)
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// get 请求
export function fetchGet (url, params) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: params })
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

// 下载文件 （新 xumin）
export function downLoadFiles (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params, { responseType: 'blob' })
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
// 下载文件
export function downLoadFile (url, params) {
  return new Promise((resolve, reject) => {
    axios.post(url, params, { responseType: 'blob' })
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
